import tw from "tailwind-styled-components";

export const Paragraph = tw.p`
  text-[17px]
  md:text-[15px]

  leading-[25px]
  md:leading-[20px]

  tracking-[-0.3px]
  md:tracking-[-0.25px]

  font-400
`;
