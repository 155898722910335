import tw from "tailwind-styled-components"

export const Subheading = tw.h3`
  text-[30px]
  md:text-[21px]

  leading-[31px]
  md:leading-[24px]

  tracking-[-1px]
  md:tracking-[-0.3px]

  font-600
`
