import tw from "tailwind-styled-components"

export const Heading = tw.h2`
  text-[50px]
  md:text-[30px]

  leading-[60px]
  md:leading-[31px]

  tracking-[-3px]
  md:tracking-[-1px]

  font-600
`
