import * as React from "react";

import { GrayPhrase, PageHeading, Paragraph, Subheading } from "@components/typography";
import { PageLayout, SEO } from "@components/page-layout";
import { GatsbyLink } from "@components/gatsby-link";
import { Box } from "@components/box";

const Sitemap = () => (
  <PageLayout>
    <SEO title="Sitemap" />
    <Box className="text-center max-w-[660px] mx-auto mb-[12rem] mt-[8.25rem] md:mb-[6rem] md:mt-[4rem] sm:px-[22px]">
      <PageHeading className="!w-full mb-[22px]">Sitemap</PageHeading>
      <Paragraph>
        <GrayPhrase>
        Curabitur quam erat, sollicitudin et congue quis, elementum eu sapien. Pellentesque ut massa nec nunc sagittis condimentum eu at arcu.
        </GrayPhrase>
      </Paragraph>
    </Box>
    <Box display="flex" className="mx-auto max-w-[1320px] justify-between lg:px-[22px] md:flex-wrap md:justify-evenly">
      <Box className="w-[396px]">
        <Subheading className="mb-[2.75rem]">iCash</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="/">Home</GatsbyLink></li>
          <li><GatsbyLink to="/why-icash">Why use us</GatsbyLink></li>
          <li><GatsbyLink to="#">How it works</GatsbyLink></li>
          <li><GatsbyLink to="#">iCASH Reviews</GatsbyLink></li>
          <li><GatsbyLink to="#">iCASH Loan App</GatsbyLink></li>
          <li><GatsbyLink to="#">My Account</GatsbyLink></li>
          <li><GatsbyLink to="#">Get a loan now</GatsbyLink></li>
        </ul>
        <Subheading className="mb-[2.75rem]">Legal</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="/responsible-lending">Responsible Lending</GatsbyLink></li>
          <li><GatsbyLink to="/terms-of-use-and-conditions">Terms of use and conditions</GatsbyLink></li>
          <li><GatsbyLink to="/credit-counsellors">Credit counsellors</GatsbyLink></li>
          <li><GatsbyLink to="#">Privacy policy</GatsbyLink></li>
        </ul>
        <Subheading className="mb-[2.75rem]">Help</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="/faq">FAQ</GatsbyLink></li>
          <li><GatsbyLink to="/contact-us">Contact us</GatsbyLink></li>
          <li><GatsbyLink to="#">Forgot Password</GatsbyLink></li>
        </ul>
        <Subheading className="mb-[2.75rem]">Resources</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="/icash-vs-other-lenders">iCASH vs other lenders</GatsbyLink></li>
          <li><GatsbyLink to="#">Loan Budgeting</GatsbyLink></li>
          <li><GatsbyLink to="/blog">Blog</GatsbyLink></li>
          <li><GatsbyLink to="/key-terms">Key Terms</GatsbyLink></li>
        </ul>
        <Subheading className="mb-[2.75rem]">More</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="#">Fast Loan</GatsbyLink></li>
          <li><GatsbyLink to="#">Education Center</GatsbyLink></li>
        </ul>
      </Box>
      <Box className="w-[396px]">
        <Subheading className="mb-[2.75rem]">Provinces and cities</Subheading>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="/payday-loans-alberta">Alberta</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Calgary</GatsbyLink></li>
          <li><GatsbyLink to="#">Edmonton</GatsbyLink></li>
          <li><GatsbyLink to="#">Medicine Hat</GatsbyLink></li>
          <li><GatsbyLink to="#">Wood Buffalo</GatsbyLink></li>
          <li><GatsbyLink to="#">Red Deer</GatsbyLink></li>
          <li><GatsbyLink to="#">Airdrie</GatsbyLink></li>
          <li><GatsbyLink to="#">St. Albert</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">British Columbia</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Cancouver</GatsbyLink></li>
          <li><GatsbyLink to="#">Burnaby</GatsbyLink></li>
          <li><GatsbyLink to="#">Victoria</GatsbyLink></li>
          <li><GatsbyLink to="#">Kamloops</GatsbyLink></li>
          <li><GatsbyLink to="#">Abbotsford</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">Manitoba</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Winnipeg</GatsbyLink></li>
          <li><GatsbyLink to="#">Dauphin</GatsbyLink></li>
          <li><GatsbyLink to="#">Morden</GatsbyLink></li>
          <li><GatsbyLink to="#">Portage La Prairie</GatsbyLink></li>
          <li><GatsbyLink to="#">Selkirk</GatsbyLink></li>
          <li><GatsbyLink to="#">The Pas</GatsbyLink></li>
          <li><GatsbyLink to="#">Winkler</GatsbyLink></li>
          <li><GatsbyLink to="#">Brandon</GatsbyLink></li>
          <li><GatsbyLink to="#">Steinbach</GatsbyLink></li>
          <li><GatsbyLink to="#">Thompson</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">New Brunswick</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Moncton</GatsbyLink></li>
          <li><GatsbyLink to="#">Saint John</GatsbyLink></li>
          <li><GatsbyLink to="#">Fredericton</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">Nova Scotia</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Halifax</GatsbyLink></li>
          <li><GatsbyLink to="#">Cap Breton</GatsbyLink></li>
          <li><GatsbyLink to="#">Dartmouth</GatsbyLink></li>
          <li><GatsbyLink to="#">Truro</GatsbyLink></li>
          <li><GatsbyLink to="#">Sydney</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">Ontario</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[2.75rem] text-primary">
          <li><GatsbyLink to="#">Toronto</GatsbyLink></li>
          <li><GatsbyLink to="#">Ottawa</GatsbyLink></li>
          <li><GatsbyLink to="#">Mississauga</GatsbyLink></li>
          <li><GatsbyLink to="#">Brampton</GatsbyLink></li>
          <li><GatsbyLink to="#">Hamilton</GatsbyLink></li>
          <li><GatsbyLink to="#">Windsor</GatsbyLink></li>
          <li><GatsbyLink to="#">London</GatsbyLink></li>
          <li><GatsbyLink to="#">Niagara Falls</GatsbyLink></li>
          <li><GatsbyLink to="#">Markham</GatsbyLink></li>
          <li><GatsbyLink to="#">Kitchener</GatsbyLink></li>
          <li><GatsbyLink to="#">Barrie</GatsbyLink></li>
          <li><GatsbyLink to="#">Oshawa</GatsbyLink></li>
          <li><GatsbyLink to="#">Brantford</GatsbyLink></li>
          <li><GatsbyLink to="#">Guelph</GatsbyLink></li>
          <li><GatsbyLink to="#">Burlington</GatsbyLink></li>
          <li><GatsbyLink to="#">St. Catherines</GatsbyLink></li>
          <li><GatsbyLink to="#">Sudbury</GatsbyLink></li>
          <li><GatsbyLink to="#">Cambridge</GatsbyLink></li>
          <li><GatsbyLink to="#">Thunder Bay</GatsbyLink></li>
          <li><GatsbyLink to="#">Scarborough</GatsbyLink></li>
          <li><GatsbyLink to="#">Oakville</GatsbyLink></li>
          <li><GatsbyLink to="#">Kingston</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">Prince Edward Island</GatsbyLink></Box>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="#">Charlottetown</GatsbyLink></li>
          <li><GatsbyLink to="#">Summerside</GatsbyLink></li>
        </ul>
        <Box className="text-[19px] font-600 tracking-[-0.5px] mb-[1.75rem]"><GatsbyLink to="#">Saskatchewan</GatsbyLink></Box>
      </Box>
      <Box className="w-[396px]">
        <Subheading className="mb-[2.75rem]">Categories</Subheading>
        <ul className="list-inside list-disc mb-[4.125rem] text-primary">
          <li><GatsbyLink to="/borrow-money-online">Borrow Money</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Installment Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Cash Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Advance</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans Near Me</GatsbyLink></li>
          <li><GatsbyLink to="#">Short Term Loans Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">Same Day Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Same Day Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Express Loans</GatsbyLink></li>
          <li><GatsbyLink to="/instant-loans">Instant Payday Loans Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">Emergency Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Weekend Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">ODSP Payday Loans Online</GatsbyLink></li>
          <li><GatsbyLink to="#">Holiday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Child Tax Cash Advance Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Private Lenders Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Money Lenders</GatsbyLink></li>
          <li><GatsbyLink to="#">Small Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">What is iCASH?</GatsbyLink></li>
          <li><GatsbyLink to="#">Online Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">CPP Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">One Month Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Easy Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">E-transfer Payday Loans in Canada 24/7</GatsbyLink></li>
          <li><GatsbyLink to="#">How Do I Qualify for a Payday Loan?</GatsbyLink></li>
          <li><GatsbyLink to="#">Unsecured Personal Loans for Bad Credit with Instant Decision in Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">Instant Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Ontario</GatsbyLink></li>
          <li><GatsbyLink to="#">Best Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="/personal-loans">Personal Loans in Canada</GatsbyLink></li>
          <li><GatsbyLink to="/credit-builder-loans">Credit builder loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Personal Loans for Bad Credit</GatsbyLink></li>
          <li><GatsbyLink to="#">Quick Loans in Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">EI Loans in Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">How to Get a Loan</GatsbyLink></li>
          <li><GatsbyLink to="#">Money Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Guaranteed Approval Loans for Poor Credit</GatsbyLink></li>
          <li><GatsbyLink to="#">Need Money Now</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans with No Credit Check</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Toronto</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Alberta</GatsbyLink></li>
          <li><GatsbyLink to="#">Direct Lenders</GatsbyLink></li>
          <li><GatsbyLink to="#">Loan Places</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans BC</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Edmonton</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Calgary</GatsbyLink></li>
          <li><GatsbyLink to="#">Bad Credit Loans Winnipeg</GatsbyLink></li>
          <li><GatsbyLink to="#">Unsecured Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Guaranteed Payday Loans No Matter What in Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans for Bad Credit</GatsbyLink></li>
          <li><GatsbyLink to="#">New Payday Loan Companies</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">AISH Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Faxless Payday Loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Loan Agency</GatsbyLink></li>
          <li><GatsbyLink to="#">Quick Cash</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans with Government Benefits</GatsbyLink></li>
          <li><GatsbyLink to="#">Payday Loans for Disability Income in Canada</GatsbyLink></li>
          <li><GatsbyLink to="#">Car repair loans</GatsbyLink></li>
          <li><GatsbyLink to="#">Loans for Self-Employed</GatsbyLink></li>
        </ul>
      </Box>
      <Box className="hidden md:block w-[396px]" />
    </Box>
  </PageLayout>
);

export default Sitemap;
