import tw from "tailwind-styled-components"
import styled from "styled-components";

const StyledPageHeading = styled.h1`
  @media only screen and (max-width: 1440px) {
    width: auto;
  }
`

export const PageHeading = tw(StyledPageHeading)`
  text-[63px]
  md:text-[37px]

  leading-[68px]
  md:leading-[50px]

  tracking-[-3px]
  md:tracking-[-1.5px]

  font-600
`