import Link from "gatsby-link"
import tw from "tailwind-styled-components"

import { GatsbyLinkProps } from "./gatsby-link"

export const GatsbyLink = tw(Link)<GatsbyLinkProps>`
  ${p =>
    p.inheritStyle ? "" : "text-primary hover:text-primary-emphasis font-500"}
`

GatsbyLink.defaultProps = {
  inheritStyle: false,
}
